import React, { Component } from 'react';
import { connect } from 'react-redux';
import { sendFeedback, resetFeedback } from '../actions';
import { isSendingFeedback, feedbackSuccessful } from '../reducer';
import GBIFeedbackButton from '../../util/buttons/components/GBIFeedbackButton';
import Modal from '../../main/components/Modal';
import PropTypes from 'prop-types';
import './gbi-feedback-form.scss';
import GBIButton from '../../util/buttons/components/GBIButton';
import { validateEmail } from '../../util/email';
import LoadingIcon from '../../util/icons/components/LoadingIcon';
import ArrowIcon from '../../util/icons/components/ArrowIcon';

const mapStateToProps = state => ({
  sending: isSendingFeedback(state),
  sent: feedbackSuccessful(state)
});

const mapDispatchToProps = dispatch => ({
  send: (message, email) => dispatch(sendFeedback(message, email)),
  reset: () => dispatch(resetFeedback())
});

class FeedbackForm extends Component {
  constructor (props) {
    super(props);
    this.state = {
      showValidation: false,
      expanded: false,
      email: '',
      emailValid: false,
      message: '',
      messageValid: false
    };
    this.containerRef = React.createRef();
    this.emailRef = React.createRef();
    this.messageRef = React.createRef();
    this.toggleVisibility = this.toggleVisibility.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside);
    if (!this.closeRef || this.closeRef.current === null) return;
    this.closeRef.current.scrollIntoView({ behavior: 'smooth' });
  }
  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  handleClickOutside (event) {
    if (this.containerRef.current &&
    !this.containerRef.current.contains(event.target)) {
      this.close();
    }
  }
  toggleVisibility () {
    this.setState({ expanded: !this.state.expanded });
  }
  close () {
    this.props.reset();
    this.setState({ expanded: false, message: '', messageValid: false });
  }
  handleEmailChange () {
    const email = this.emailRef.current.value.toLowerCase();
    const emailValid = validateEmail(email);
    this.setState({ emailValid, email });
  }
  handleMessageChange () {
    const message = this.messageRef.current.value;
    const messageValid = message.length > 4;
    this.setState({ message, messageValid });
  }
  send () {
    if (this.state.messageValid && this.state.emailValid) {
      this.props.send(this.state.message, this.state.email);
    } else {
      this.setState({ showValidation: true });
    }
  }
  renderButtonOrResponse () {
    if (this.props.sent) {
      return <p>{ 'Thanks! We\'ve got your message' }</p>;
    } else {
      return (
        <GBIButton label="send your feedback"
          onClick={ this.send.bind(this) } >
          <ArrowIcon />
        </GBIButton>
      );
    }
  }
  renderIcon () {
    return <GBIFeedbackButton onClick={ this.toggleVisibility } />;
  }
  renderInputs () {
    if (this.props.sending) {
      return <div className="sending">
        <LoadingIcon />
      </div>;
    }
    const emailClass = !this.state.emailValid && this.state.showValidation ?
      'error' : '';
    const messageClass = !this.state.messageValid && this.state.showValidation ?
      'error' : '';
    return (
      <form>
        <input
          placeholder='email'
          ref={ this.emailRef }
          value={this.state.email || '' }
          className={ emailClass }
          onChange={ this.handleEmailChange.bind(this) } />
        <textarea
          placeholder='message'
          ref={ this.messageRef }
          value={ this.state.message || '' }
          className={ messageClass }
          onChange={ this.handleMessageChange.bind(this) } />
        <div className="controls">
          { this.renderButtonOrResponse() }
        </div>
      </form>
    );
  }
  renderForm () {
    return (
      <Modal close={ this.close } pos={ window.scrollY }>
        <div ref={ this.containerRef } className="gbi-feedback-form" >
          <h1>{ 'Let\'s talk beef' }</h1>
          <h2>Please share your feedback</h2>
          { this.renderInputs() }
        </div>
      </Modal>
    );
  }
  render () {
    if (this.state.expanded) {
      return this.renderForm();
    } else {
      return this.renderIcon();
    }
  }
}

FeedbackForm.propTypes = {
  sending: PropTypes.bool,
  sent: PropTypes.bool,
  send: PropTypes.func,
  reset: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackForm);
