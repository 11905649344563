import React from 'react';
import PropTypes from 'prop-types';
import './gbi-feedback-button.scss';

/* eslint-disable max-len */
const GBIFeedbackButton = ({ onClick }) => (
  <div onClick={ onClick } className="gbi-feedback-button">
    <svg className="feedback-icon" viewBox="0 0 78.93 91.927">
      <path d="M69.24,1.036H10.76A10.255,10.255,0,0,0,.535,11.261V64.953A10.255,10.255,0,0,0,10.76,75.177H21.082V92.964L38.868,75.177H69.24A10.255,10.255,0,0,0,79.465,64.953V11.261A10.255,10.255,0,0,0,69.24,1.036ZM53.731,56.664,40.013,49.452,26.3,56.664l2.62-15.274-11.1-10.818,15.336-2.228,6.858-13.9,6.859,13.9,15.336,2.228L51.111,41.39Z" transform="translate(-0.535 -1.036)"/>
    </svg>
  </div>
);

GBIFeedbackButton.propTypes = {
  onClick: PropTypes.func
};

export default GBIFeedbackButton;
